/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 14:58:21
 * @LastEditors: silencc
 * @LastEditTime: 2020-11-16 14:34:54
 */

// import { parseTime } from '@/plugins/lib/modules/date'
// import { remit, getType } from '@/utils/options'

export var columns = function columns(ctx) {return [
  {
    label: '序号',
    type: 'index',
    width: 60 },

  {
    label: '姓名',
    kname: 'userName' },

  {
    label: '角色',
    kname: 'roleName' },

  {
    label: '操作模块',
    kname: 'module' },

  {
    label: '操作时间',
    kname: 'createAt' }

  // {
  //   label: '操作',
  //   key: 'btns',
  //   width: 160
  // }
  ];};


export var searchOption = [
{
  kname: 'userName',
  label: '搜索：',
  component: {
    name: 'el-input',
    props: {
      clearable: true,
      placeholder: '请输入员工编号、姓名' } } },



{
  kname: 'roleId',
  label: '角色：',
  component: {
    name: 'selector',
    props: {
      filterable: true,
      type: 'roles' } } },



{
  kname: 'module',
  label: '操作模块：',
  component: {
    name: 'el-input',
    props: {
      clearable: true } } },



{
  kname: 'createAt',
  label: '操作时间：',
  component: {
    name: 'c-date-picker',
    props: {
      clearable: true,
      type: 'date',
      format: 'yyyy-MM-dd',
      valueFormat: 'yyyy-MM-dd' } } }];